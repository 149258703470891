.subtotal-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.product-card {
  background: #f4f4f4;
  border-radius: 10px;
}

.product-name {
  font-size: 0.8rem;
}

.product-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.add-to-cart-button {
  background: #000000;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
}

.product-card {
  background: #f4f4f4;
  border-radius: 10px;
  overflow: hidden;
  /* Ensure the image doesn't overflow the card's rounded corners */
}

.product-image {
  height: 150px;
  /* Maintain the image's aspect ratio */
  object-fit: cover;
  /* Cover the designated area without stretching the image */
  border-top-left-radius: 10px;
  /* Match the card's border radius */
  border-top-right-radius: 10px;
  /* Match the card's border radius */
}

.card {
  position: relative;
  background: #f6fcff;
  width: 400px;
  height: 500px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.card:focus,
.card:active {
  box-shadow: 0;
  transition: ease-in-out 0.3s;
}

@media screen and (max-width: 768px) {
  .card {
    width: 200px;
    height: 400px;
  }

  .product-image img {
    height: 90%;
    object-fit: cover;
  }

  .product-image {
    margin-top: 40px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card h3 {
  font-family: "IBM Medium";
  font-size: 0.9rem;
  font-weight: 700;
  color: #1b1b1b;
  margin: 0px;
  display: inline-block;
  text-align: center;
  padding-left: 4px;
}

.title {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.underline {
  margin-bottom: 4px;
  display: grid;
}

.product-image {
  margin-top: 40px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image img {
  height: 110%;
  object-fit: cover;
}

.info-card {
  padding-left: 15px;
}

.info-card p {
  font-family: "IBM Bold";
  margin: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-cart {
  justify-content: center;
  align-items: center;
  background: #7dd5fb;
  text-align: center;
  width: 70%;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
}

.add-cart p {
  margin: 0px;
  padding: 5px;
  font-family: "IBM Bold";
  font-size: small;
}

.popup {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.variant-selector {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line as needed */
  justify-content: center; /* Centers items horizontally in the container */
  align-items: center; /* Centers items vertically in the line */
}

.variant-box {
  display: inline-flex; /* Using inline-flex for better alignment */
  align-items: center; /* Center text vertically within each box */
  justify-content: center; /* Center text horizontally within each box */
  padding: 20px 20px;
  margin: 5px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.variant-box:hover {
  background-color: #ddd;
}

@media screen and (max-width: 768px) {
  .card {
    width: 180px;
    min-height: 350px;
  }

  .product-image img {
    height: 80%;
    object-fit: cover;
  }

  .product-image {
    margin-top: 5px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .variant-box {
    height: 20px;
    width: 20px;
    padding: 0px;
  }
  .variant-selector {
    padding: 0px;
  }
  .info-card p {
    padding-top: 16px;
    margin-bottom: 5px;
  }
}
