.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-selector button {
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer;
  border: 1.5px solid #000;
  background: #ffffff;
  border-radius: 50%;

  color: #000;
}
.quantity-selector button:hover {
  background: #000;
  color: #ffffff;
  border: 1.5px solid #ffffff;
}
.quantity-selector button:active {
  background: #000;
  color: #ffffff;
  border: 1.5px solid #ffffff;

  transform: scale(0.9);
}
.quantity-selector input {
  text-align: center;
  width: 30px;
  padding: 5px;
  margin: 0 5px;
  border-radius: 8px;
  border: 1.5px solid #000;
}
