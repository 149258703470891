/* Set the background of the entire checkout wrapper to black */
.checkout-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.checkout-content {
  width: 80%;
  background-color: #000; /* Set background color to black */
  color: #fff; /* Set text color to white for visibility */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px;
}

/* Adjust the styles for the header, subtotal, and customer info to match the uploaded design */
.checkout-header {
  text-align: center;
  margin-bottom: 20px; /* Adjust the margin as needed */
}

.checkout-header h2 {
  color: #fff; /* Set text color to white for visibility */
}

.subtotal {
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: center; /* Center the subtotal text */
}

.subtotal .total {
  font-weight: bold;
  color: #feb836; /* Set subtotal price color */
  font-size: 1.5rem;
}
.customer-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* Style the customer info inputs */
.customer-info input {
  background: transparent; /* Set input background to transparent */
  border: 1px solid #fff; /* Border color white for visibility */
  background: #fff;
  border-radius: 5px;
  font-size: 16px;
  margin: 10px; /* Adjust margin as needed */
  color: #000; /* Set input text color to white for visibility */
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
}
/* Styles for inputs when there is an error */
.customer-info .input-error {
  border: 3px solid rgb(250, 120, 120); /* Make the border color red to indicate an error */
  /* Optionally, you could change the background or text color to enhance visibility */
}
.error-message {
  margin: 0px;
  color: rgb(250, 120, 120);
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* Style the payment method buttons */
.payment-methods button {
  border: 2px solid #feb836; /* Gold border for buttons */
  background: transparent;
  color: #feb836; /* Gold text for buttons */
  padding: 10px 20px; /* Adjust padding as needed */
  margin: 10px; /* Adjust margin as needed */
  border-radius: 25px; /* Rounded corners for buttons */
  cursor: pointer;
}

.payment-methods button:hover {
  background-color: #feb836; /* Button background on hover */
  color: #000; /* Button text color on hover */
}

/* Adjust table styles for dark background */
.checkout-footer table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Adjust margin as needed */
  font-family: Arial, sans-serif;
  color: #fff; /* Set text color to white for visibility */
}

.checkout-footer th,
.checkout-footer td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #333; /* Dark grey border for rows */
}

/* Adjust hover effect for rows for dark background */
.checkout-footer tr:hover {
  background-color: #333; /* Darker row background on hover */
}

/* Adjust the style for the last column (prices) */
.checkout-footer td:last-child {
  text-align: right;
}
