.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px; /* Adjust as needed */
  height: 100%;
  background: rgb(0, 0, 0);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  color: white;
  overflow-y: auto; /* Enables vertical scrolling */
  z-index: 9;
}
.close-button {
  border: 1px solid white;
  background: none;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.close-button:hover {
  background: white;
  color: black;
}
.close-button:active {
  transform: scale(0.9);
}
.checkout-button {
  margin-top: 10px;
  padding: 5px 20px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.3rem; /* Adjust the font size as needed */
  transition: 0.3s;
  width: 200px;
  height: 60px;
  max-width: 100%;
  margin-bottom: 20px;
  background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)) padding-box,
    linear-gradient(to right, #fbe191, #ffb700) border-box;
  border-radius: 50em;
  border: 2.5px solid transparent;
  transform: scale(0.7);
}
.checkout-button:active {
  transform: scale(0.6);
  background: linear-gradient(to right, #fbe191, #ffb700) border-box;
  color: #000;
}
.subtotal {
  font-size: 1.2rem;
  font-weight: lighter;
}
.total {
  font-size: 1.3rem;
}
.cart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-item > :first-child {
  margin-top: 20px; /* Adjust the value as needed */
}
.cart-item > :last-child {
  margin-bottom: 20px; /* Adjust the margin as needed */
}
.box-container {
  margin-bottom: 5px;
  border: 1px solid white;
  height: 40px;
  margin-top: 10px;
  padding: 20px;
  display: inline-block;
  font-size: 0.7rem; /* Consider adjusting font size based on device width */
  border-radius: 5px;
  max-width: 100px; /* Prevents the box from exceeding its parent's width */
  overflow: hidden; /* Hides overflow */
  text-overflow: ellipsis; /* Adds ellipsis to overflowed text */
  text-align: center;
  font-size: 0.7rem; /* Smaller text on smaller screens */
}

.cart-item-quantity-remove {
  display: flex;
  align-items: center;
  text-align: center;
}
.remove-button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-button svg {
  transform: scale(0.8); /* Adjust the scale value as needed */
}

.sidebar-quantity {
  transform: scale(0.9); /* Adjust the scale value as needed */
}

.sidebar-quantity button {
  cursor: pointer;
  background: #000;
  color: #ffffff;
  border: 1.5px solid #ffffff;
  color: #ffffff;
}
.sidebar-quantity button:hover {
  background: #ffffff;
  color: #000;
  border: 1.5px solid #ffffff;
}
.sidebar-quantity button:active {
  transform: scale(0.7); /* Adjust the scale value as needed */
}
