.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh; /* Use the full screen height */
  padding: 20px; /* Add some padding around */
  background-color: #f0f0f0; /* Light grey background */
}

.qr-wrapper img {
  max-width: 80%; /* Make the image a maximum of 80% of its container's width */
  max-height: 80vh; /* Make the image a maximum of 80% of the screen height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to lift the QR code off the page */
  border-radius: 10px; /* Optional: round the corners of the QR code image */
}
