/* Base styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif; /* Choose your preferred font */
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Navigation styles */
.nav {
  display: flex;
  gap: 20px;
  list-style: none;
}
.car-shop-button {
  padding: 10px 20px;
  border: 2px solid #efbe3f; /* Your preferred color */
  background: #ffe5a2; /* Your preferred color */
  border-radius: 5px;
  cursor: pointer;
  list-style: none;
  color: #000000;
}
.car-shop-button:active {
  background: #f8d27e; /* Your preferred color */
  transform: scale(0.9);
  color: #000000;
}
.nav-item {
  text-decoration: none;
  color: black; /* Your preferred color */
}

/* Filter button styles */
.filter-button {
  padding: 10px 20px;
  border: 1.5px solid #000000; /* Your preferred color */
  border-radius: 5px;
  background: #fdde90; /* Your preferred color */
  margin-left: 20px;
  cursor: pointer;
  list-style: none;
  color: #000000;
}
.filter-button:active {
  background: #f8d27e; /* Your preferred color */
  transform: scale(0.9);
}
/* Product grid styles */
.products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.product-card {
  border: 1px solid #ddd; /* Your preferred color */
  padding: 20px;
  text-align: center;
}

.product-name {
  margin-bottom: 10px;
}

/* Utility classes */
.hidden {
  display: none;
}
/* iPad */

/* iPad */

/* iPhone 12 Pro Max and iPhone 14 Pro Max */
@media only screen and (max-width: 428px) {
  /* iPhone 12 Pro Max and iPhone 14 Pro Max width */
  .car-shop-button {
    transform: scale(0.8);
  }
  .nav-item {
    font-size: 14px; /* Adjust font size if needed */
  }
  .products-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
  }
}
