/* styles/StockAdmin.module.css */

.container {
  padding: 20px;
}

.productsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.productCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.productCard img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.productCard h3 {
  margin: 8px 0;
}

.productCard p {
  margin: 4px 0;
}

.productCard input {
  width: 60px;
}
