.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
}

.logo svg {
  max-width: 100%;
  max-height: 100%;
}
