.ventaPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.ventaPopupContent {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.ventaPopupContent h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.ventaPopupContent p {
  margin-bottom: 10px;
}

.paymentDetails label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.paymentDetails input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Asegura que el tamaño de la fuente sea al menos 16px */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 5px;
  text-align: left;
}

.table th {
  background-color: #f9f9f9;
  border-bottom: 2px solid #ddd;
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid #eee;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.secondary {
  background-color: #6c757d;
  margin-left: 10px;
}

.secondary:hover {
  background-color: #5a6268;
}

select,
input[type="number"] {
  width: calc(100% - 120px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 16px !important; /* Asegura que el tamaño de la fuente sea al menos 16px */
}

.tableActions {
  display: flex;
  align-items: center;
}

.tableActions button {
  margin-left: 10px;
}

.addProductRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.quantityInput {
  width: 50px;
  padding: 6px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px !important; /* Asegura que el tamaño de la fuente sea al menos 16px */
}
