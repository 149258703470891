/* InvoiceStyles.css */
.wrapper-invoice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.invoice {
  padding: 2em;
  max-width: 600px;
  height: 700px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.invoice h1 {
  font-size: 2em;
  color: #333;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}

.invoice p {
  color: #666;
  line-height: 1.5;
  margin: 0.5em 0;
}

.invoice ul {
  list-style: none;
  padding: 0;
  margin: 1em 0;
}

.invoice li {
  padding: 0.5em 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.invoice li:last-child {
  border-bottom: none;
}

.invoice li span {
  font-weight: bold;
}

.invoice p.total {
  font-size: 1.5em;
  color: #333;
  border-top: 2px solid #eaeaea;
  padding-top: 0.5em;
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}

.capture-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.capture-button:hover {
  background-color: #45a049;
}

@media print {
  .capture-button {
    display: none;
  }
}
