.sidebar-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #ffe5a2;
  color: #000;
  border: #efbe3f;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99;
}
.sidebar-toggle-button:hover {
  background-color: #ffb700;
}
.sidebar-toggle-button:active {
  background: #f8d27e; /* Your preferred color */
  transform: scale(0.9);
  color: #000000;
}
.sidebar-toggle-button.show {
  opacity: 1;
}
