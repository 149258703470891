.filter-container {
  width: 100%;
  height: 40px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
  overflow-x: auto;
}

.filter-header::-webkit-scrollbar {
  height: 6px;
}

.filter-header::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.filter-header::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.filter-option {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

.filter-option:hover {
  background-color: #eee;
}

.filter-option.active {
  color: #fff;
}
