/* General styles */
.titulo {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.78rem;
  width: 100%;
  overflow-x: auto; /* Ensure the table can scroll horizontally */
}

.pedidos-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.pedidos-table th,
.pedidos-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.status-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.red {
  background-color: #ff5f57;
}

.green {
  background-color: #27c93f;
}

.status {
  padding: 4px 8px;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  font-size: 0.5rem;
  display: inline-block;
  cursor: pointer;
}

.status.pagado {
  background-color: #56db6a;
  color: rgb(255, 255, 255);
}

.status.por_pagar {
  background-color: #ff5f57;
  color: white;
}

.pedidos-table thead th {
  background-color: #f2f2f200;
}

.pedidos-table tbody tr {
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.pedidos-table tbody tr:hover {
  background-color: #f9f9f9;
}

.pedidos-table tbody tr.swiped {
  transform: translateX(-50px);
  background-color: #f8d7da;
}

.modal {
  height: 50px;
  width: 50px;
  background: gray;
}

.modal-trigger {
  width: 50px;
  height: 50px;
  background-color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.date-filter label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}

.date-filter input[type="date"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-top: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.date-filter input[type="date"]:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
  outline: none;
}

.button-excel {
  align-self: flex-start; /* Align button to the start */
  font-size: 0.9rem;
  color: #333;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-top: 5px;
  transition: all 0.3s ease;
  background: white;
  cursor: pointer;
  margin-bottom: 15px;
}
.button-excel:hover {
  background: #f2f2f2;
}

/* Responsive styles */
@media (max-width: 600px) {
  .titulo {
    font-size: 1.5rem;
  }

  .wrapper {
    font-size: 0.65rem;
  }

  .pedidos-table th,
  .pedidos-table td {
    padding: 6px;
  }

  .status {
    font-size: 0.4rem;
    padding: 3px 6px;
  }

  .date-filter label {
    font-size: 0.8rem;
  }

  .date-filter input[type="date"] {
    padding: 6px;
    font-size: 0.8rem;
  }

  .button-excel {
    font-size: 0.8rem;
    padding: 6px;
  }

  .summary {
    font-size: 0.8rem;
  }
}
