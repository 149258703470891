.contacto-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contacto-title {
  text-align: center;
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

.contacto-total {
  text-align: center;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.search-bar {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-contact-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.add-contact-button:hover {
  background-color: #0056b3;
}

.contacto-list {
  list-style-type: none;
  padding: 0;
}

.contacto-item {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contacto-item p {
  margin: 5px 0;
}

.contacto-item strong {
  color: #555;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
}

.popup-inner h2 {
  margin-top: 0;
}

.popup-inner form {
  display: flex;
  flex-direction: column;
}

.popup-inner label {
  margin-bottom: 10px;
}

.popup-inner input {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-inner button {
  padding: 10px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.popup-inner button:hover {
  background-color: #0056b3;
}

.popup-inner button[type="button"] {
  background-color: #ccc;
}

.popup-inner button[type="button"]:hover {
  background-color: #aaa;
}
